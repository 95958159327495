import { useMutation } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
export type PaymentInitModel = {
  product_price_id: number | undefined;
  product_id: number;
  gateway: string;
  redirect_url: string;
  payment_type_id: number;
  success_url?: string;
};

const usePaymentInitMutation = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: PaymentInitModel) => {
      const response = await fetchRedirect(createUrl('/payment/initialize'), {
        method: 'POST',
        body: JSON.stringify(data),
      });
      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
  });
  return { mutate, isLoading };
};

export default usePaymentInitMutation;
