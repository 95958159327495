import React, { FC, useContext } from 'react';
import styles from './Balance.module.scss';
import AuthContext from '../../context/auth/authContext';
import { useTranslation } from 'react-i18next';

const Balance: FC = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { t } = useTranslation();

  return (
    <h3 className={styles.heading}>
      <span>{t('balance')}</span>
      <span>{user && user.balance?.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</span>
    </h3>
  );
};

export default Balance;
