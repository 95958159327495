import React, { FC, Suspense, useContext, useEffect, useState } from 'react';
import { queryClient } from 'src/core/root/components/ReactQueryProvider/ReactQueryProvider';
import Container from 'react-bootstrap/Container';
import styles from './MovieDetailsPage.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import { CustomSpinner } from '../../../shared/Spinner';
import Carousel from 'react-bootstrap/Carousel';
import { LoginButton } from '../../../components/UI/LoginButton';
import { useTranslation } from 'react-i18next';
import { BigButton } from '../../../components/UI/BigButton';
import { PinConfirmationModal } from '../../../components/UI/Modals/PinConfirmationModal';
import { OrderModal } from '../../../components/UI/Modals/OrderModal';
import { Player } from '../../../components/UI/Player';
import { SuspenseImg } from '../../../utils/imageSuspense';
import { PromotionBadge } from '../../../components/PromotionBadge';
import { useMovieQuery, usePrefetchQuery } from './query';
import { MovieDetailModel } from './query/types';
import { useMovieDetailBookmarksMutation, useMoviePurchaseMutation } from './mutation';
import { Spinner } from 'react-bootstrap';
import { CustomLink } from 'src/components/CustomLink';
import { generateImageUrl } from '../../../utils/cdnService';
import { ErrorModal } from '../../../components/UI/Modals/ErrorModal';

const MovieDetailsPage: FC = () => {
  let { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, login, user, updateUser, userLocation } = authContext;
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [show, setShow] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [pin, setPin] = useState<string>('');
  const [playerStatus, setPlayerStatus] = useState<boolean>(false);
  const [orderVisibility, setOrderVisibility] = useState<boolean>(false);
  const [selectedButton, setSelectedButton] = useState<string>('');
  const [selectedPrice, setSelectedPrice] = useState<number>(0);
  const [isRent, setIsRent] = useState<boolean>(false);
  const [streamLimitReached, setStreamLimitReached] = useState<boolean>(false);
  const [cfMediaID, setCfMediaID] = useState<string>('');

  const { movie, isMovieLoading, refetchMovieQuery } = useMovieQuery(id as string, isAuthenticated);
  const { mutate } = useMoviePurchaseMutation(id as string);
  const { mutateBookmark, isBookmarksMutationLoading } = useMovieDetailBookmarksMutation();
  const location = useLocation();

  const [form, setForm] = useState({
    username: '',
    pin: '',
  });
  usePrefetchQuery();

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!form.username || !pin) return;
    e.preventDefault();
    let data = {
      username: form.username,
      pin,
    };
    login(data);
  };

  const renderTags = (tags: MovieDetailModel['tags']) => {
    if (tags?.length > 0) {
      return (
        <React.Fragment>
          <span className={styles.infoLabel}>Kategorien: </span>
          {tags.map((tag, i) => (
            <CustomLink key={tag.id} to={`/${language}/tag/${tag.id}`}>
              {tag.name}
              {tags?.length - 1 !== i ? ', ' : ''}{' '}
            </CustomLink>
          ))}
        </React.Fragment>
      );
    }
  };

  useEffect(() => {
    if (streamLimitReached) {
      setShow(false);
    }
  }, [streamLimitReached]);

  useEffect(() => {
    sessionStorage.removeItem('savedPath');
  }, []);

  const onHide = () => {
    refetchMovieQuery();
    setPlayerStatus(false);
  };

  const renderStream = () => {
    if (!isMovieLoading && movie && isAuthenticated && playerStatus) {
      return <Player banner={movie.banner} onHide={onHide} id={movie.id} controls muted src={cfMediaID} />;
    }
  };

  const handleClose = () => setShow(false);

  const returnPrice = (movie: any, type: string) => {
    if (movie.promo_prices && movie.promo_prices[type]) {
      if (movie.promotion_only_downloads) {
        return type.includes('download') ? movie.promo_prices[type].price : movie[type].price;
      }
      return movie.promo_prices[type].price;
    }
    return movie[type].price;
  };

  const priceText = (movie: any, type: string, text: string, additionalText = '') => {
    let regularPrice = movie[type].price.toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR',
    });
    let promoPrice = null;
    if (movie.promo_prices && movie.promo_prices[type] && movie.promo_prices[type].price) {
      promoPrice = movie.promo_prices[type].price.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
      if (movie.promotion_only_downloads) {
        if (!type.includes('download')) {
          promoPrice = null;
        }
      }
    }
    const price = promoPrice || regularPrice;

    return (
      <div className={styles.priceWrapper}>
        <p>{`${text}`}</p>
        <div className={styles.prices}>
          {promoPrice && (
            <span className={styles.price}>
              <span className={styles.strikedPrice}>{regularPrice}</span>
            </span>
          )}
          <span> {`${movie.stream?.welcome_bonus ? '' : price} `} </span>
          <span style={{ marginLeft: '5px' }}>{additionalText}</span>
        </div>
      </div>
    );
  };

  const renderDownloadSD = () => {
    if (movie.download?.price !== 0) {
      return (
        <BigButton
          sm={6}
          md={user?.subscribed === false && movie.banner === 'blue-movie-plus' ? 4 : 4}
          onClick={() => {
            setOrderVisibility(true);
            setSelectedButton('sd');
            setSelectedPrice(returnPrice(movie, 'download'));
            setIsRent(false);
          }}
          icon="fa fa-download"
          text={priceText(movie, 'download', 'KAUFEN SD FüR')}
        />
      );
    } else {
      return (
        <BigButton
          active
          sm={6}
          md={user?.subscribed === false && movie.banner === 'blue-movie-plus' ? 4 : 4}
          onClick={() => {
            setShow(true);
            setSelectedButton('sd');
            setSelectedPrice(0);
          }}
          icon="fa fa-download"
          text={`Download (SD) FüR ${movie.download?.hours_to_expire}`}
        />
      );
    }
  };

  const renderDownloadHD = () => {
    if (movie['download-hd']?.price !== 0) {
      return (
        <BigButton
          sm={6}
          md={user?.subscribed === false && movie.banner === 'blue-movie-plus' ? 4 : 4}
          // onClick={() => setOrderVisibility(true)}
          onClick={() => {
            setOrderVisibility(true);
            setSelectedButton('hd');
            setSelectedPrice(returnPrice(movie, 'download-hd'));
            setIsRent(false);
          }}
          icon="fa fa-download"
          text={priceText(movie, 'download-hd', 'KAUFEN HD FüR')}
        />
      );
    } else {
      return (
        <BigButton
          active
          sm={6}
          md={user?.subscribed === false && movie.banner === 'blue-movie-plus' ? 4 : 4}
          onClick={() => {
            setShow(true);
            setSelectedButton('hd');
            setSelectedPrice(0);
          }}
          icon="fa fa-download"
          text={`Download (HD) FüR ${movie['download-hd'].hours_to_expire}`}
        />
      );
    }
  };

  const renderBmButton = () => {
    if (movie && movie.stream?.license_type === 'stream' && movie.banner === 'blue-movie-plus' && !user?.subscribed) {
      return (
        <BigButton
          sm={6}
          md={4}
          share
          onClick={() =>
            navigate(`/${language}/blue-movie-plus`, {
              state: { location: 'singleMovie' },
            })
          }
          icon="fa fa-share"
          text="Blue Movie Plus kaufen"
        />
      );
    }
  };

  const renderStreamButton = () => {
    if (movie && movie.stream?.license_type === 'subscription-stream') {
      return (
        <BigButton
          active
          md={user?.subscribed === false && movie.banner === 'blue-movie-plus' ? 4 : 4}
          sm={6}
          onClick={() => {
            setShow(true);
            setSelectedButton('play');
            setSelectedPrice(0);
          }}
          icon="fa fa-play-circle"
          text="Film ansehien"
        />
      );
    } else {
      if (movie.stream?.price === 0 && movie.stream.hours_to_expire) {
        return (
          <BigButton
            active
            md={user?.subscribed === false && movie.banner === 'blue-movie-plus' ? 4 : 4}
            sm={6}
            onClick={() => {
              setShow(true);
              setSelectedButton('play');
              setSelectedPrice(0);
            }}
            icon="fa fa-play-circle"
            text={`Ausgeliehen FüR ${movie.stream?.hours_to_expire}`}
          />
        );
      }
      return (
        <BigButton
          active={movie.stream?.price === 0}
          md={user?.subscribed === false && movie.banner === 'blue-movie-plus' ? 4 : 4}
          sm={6}
          onClick={() => {
            movie.stream?.price === 0 ? setShow(true) : setOrderVisibility(true);
            setSelectedButton('play');
            setSelectedPrice(returnPrice(movie, 'stream'));
            setIsRent(true);
          }}
          icon="fa fa-play-circle"
          text={priceText(
            movie,
            'stream',
            movie.stream?.welcome_bonus ? 'Jetzt gratis Abspielen' : 'LEIHEN FüR ',
            movie.stream?.welcome_bonus ? '' : ' / 48 STD'
          )}
        />
      );
    }
  };

  const renderMenu = () => {
    if (!isMovieLoading && movie && isAuthenticated && !loading) {
      return (
        <div className={styles.menuWrapper}>
          <Row>
            {movie && movie.stream && renderStreamButton()}
            {movie && movie['download-hd'] && renderDownloadHD()}
            {movie && movie.download && renderDownloadSD()}
            {movie && movie.stream && renderBmButton()}
          </Row>
          <PinConfirmationModal
            selectedButton={selectedButton}
            handleSuccess={handlePlayMovie}
            centered={true}
            show={show}
            onHide={handleClose}
            streamLimitReached={streamLimitReached}
          />
          <ErrorModal
            onHide={() => setStreamLimitReached(false)}
            show={streamLimitReached}
            headingText={t('orderModal.limitReached')}
            errorText={t('orderModal.limitReachedText')}
          />
          <OrderModal
            selectedButton={selectedButton}
            balance={user && (user?.balance?.toFixed(2) as string)}
            location={location.pathname}
            price={selectedPrice}
            handleSuccess={handlePlayMovie}
            centered={true}
            show={orderVisibility}
            onHide={() => setOrderVisibility(false)}
            rent={isRent}
            streamLimitReached={streamLimitReached}
          />
        </div>
      );
    }
  };

  const renderActors = (actors: MovieDetailModel['actors']) => {
    if (actors?.length > 0) {
      return (
        <React.Fragment>
          <span className={styles.infoLabel}>{t('singleMoviePage.actors')}</span>{' '}
          {actors.map((actor, i) => (
            <span key={actor.id}>
              {actor.name}
              {actors?.length - 1 !== i ? ', ' : ''}{' '}
            </span>
          ))}
        </React.Fragment>
      );
    }
  };

  const handlePlayMovie = async () => {
    let data: any = {};

    if (selectedButton === 'hd') {
      let price = null;
      if (movie.promo_prices) {
        price = movie.promo_prices?.['download-hd']?.price;
      } else {
        price = movie?.['download-hd']?.price;
      }
      data = {
        price: price,
        license_type: 'download-hd',
        content_name: movie.title,
      };
    } else if (selectedButton === 'sd') {
      data = {
        price: movie.promo_prices?.download?.price || movie.download?.price,
        license_type: 'download',
        content_name: movie.title,
      };
    } else if (selectedButton === 'play') {
      data = {
        price: movie.promo_prices?.stream?.price || movie.stream?.price,
        license_type: movie.stream?.license_type,
        content_name: movie.title,
      };
      // if welcome_bonus
      if (movie.stream?.welcome_bonus) {
        data.welcome_bonus = movie.stream?.welcome_bonus;
      }
      if (movie.stream?.real_price) {
        data.real_price = movie.stream?.real_price;
      }
    }

    try {
      mutate(data, {
        onSuccess(data: any) {
          if (selectedButton === 'sd' || selectedButton === 'hd') {
            updateUser({
              balance: data.data.balance,
            });
            setOrderVisibility(false);
            setShow(false);
            setTimeout(function () {
              document.location.href = `${data.data.url}`;
            }, 500);
          } else {
            updateUser({
              balance: data.data.balance,
            });
            setOrderVisibility(false);
            setShow(false);
            setCfMediaID(data.data.url);
            setPlayerStatus(true);
          }
          queryClient.invalidateQueries({ queryKey: [`movies_${movie.id}`] });
        },

        onError(data: any) {
          if (data.body.message === 'stream_limit_reached') {
            setStreamLimitReached(true);
          }
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const handlePinChange = (e: React.SyntheticEvent) => {
    const { nativeEvent } = e;
    handleTest((nativeEvent as InputEvent).data);
  };

  const handleTest = (input: string | null) => {
    const reg = new RegExp('^\\d+$');
    if (input && reg.test(input) && pin?.length < 4) {
      setPin(pin + input);
    } else if (input === null) {
      setPin(pin.slice(0, -1));
    }
  };

  const handleThumb = (index: number) => {
    setIndex(index);
  };

  const handleWishlist = async () => {
    mutateBookmark(
      {
        content_id: id as string,
      },
      {
        onSuccess: () => {
          refetchMovieQuery();
        },
      }
    );
  };

  const renderFavButton = () => {
    if (movie && isAuthenticated) {
      if (movie.isBookmarked) {
        return (
          <button disabled className={styles.favButton}>
            {t('singleMoviePage.wishlisted')}
            <i className="fa fa-check" />
          </button>
        );
      }
      return (
        <button onClick={() => handleWishlist()} className={styles.favButton}>
          {(isBookmarksMutationLoading || isMovieLoading) && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
          {!isBookmarksMutationLoading && t('singleMoviePage.wishlist')}
          {!isBookmarksMutationLoading && <i className="fa fa-plus" />}
        </button>
      );
    }
  };

  const renderThumbs = (movie: MovieDetailModel) => {
    const { images } = movie;
    if (images && images?.length > 0) {
      return images.map((image, index) => (
        <div key={index} onClick={() => handleThumb(index)} className={styles.thumb}>
          <SuspenseImg style={{ width: '100%' }} src={`${generateImageUrl('gcore', 66, 88, image)}`} alt="img" />
        </div>
      ));
    }
  };

  const renderMainCarousel = (movie: MovieDetailModel) => {
    const { images } = movie;

    return (
      <Carousel indicators={false} controls={false} activeIndex={index}>
        {images &&
          images.map((image) => (
            <Carousel.Item key={image}>
              {movie.promotional_image && movie.promotion_value && (
                <PromotionBadge image={movie.promotional_image} value={movie.promotion_value} />
              )}
              {movie.banner && <div className={`${styles.marker} ${styles[movie.banner]}`}></div>}
              <SuspenseImg className="d-block w-100" src={`${generateImageUrl('gcore', 367, 489, image)}`} />
            </Carousel.Item>
          ))}
      </Carousel>
    );
  };

  const renderMovieDetails = () => {
    if (isMovieLoading) {
      return <CustomSpinner />;
    }

    if (movie && !isMovieLoading) {
      return (
        <Row>
          <Col xs={12} sm={5} md={4}>
            <div className={styles.sliderWrapper}>
              {renderMainCarousel(movie)}
              <div className={styles.thumbWrapper}>{renderThumbs(movie)}</div>
            </div>
            <div className={styles.backButton}>
              <i className="fa fa-th-large" aria-hidden="true" />
              <div className={styles.back} onClick={() => navigate(-1)}>
                {t('singleMoviePage.back')}
              </div>
            </div>
          </Col>
          <Col xs={12} sm={7} md={8} lg={8}>
            <div>
              <h2 style={{ marginBottom: '20px' }} className="headline">
                {movie.title}
              </h2>
            </div>
            {renderFavButton()}
            <div className={styles.infoWrapper}>
              <div className={styles.infoItem}>{renderTags(movie.tags)}</div>
              <div className={styles.infoItem}>{renderActors(movie.actors)}</div>
              <div className={`${styles.infoItem} ${styles.inLine}`}>
                {movie.content_properties[0].year && (
                  <>
                    <span className={styles.infoLabel}>{t('singleMoviePage.year')}</span>{' '}
                    {movie.content_properties[0].year}
                  </>
                )}
                {movie.content_properties[0].country && (
                  <>
                    <span className={styles.infoLabel}>{t('singleMoviePage.country')}</span>{' '}
                    {movie.content_properties[0].country}
                  </>
                )}
                {movie.content_properties[0].director && (
                  <>
                    <span className={styles.infoLabel}>{t('singleMoviePage.director')}</span>{' '}
                    {movie.content_properties[0].director}
                  </>
                )}

                {movie.content_properties[0].duration && (
                  <>
                    <span className={styles.infoLabel}>{t('singleMoviePage.duration')}</span>{' '}
                    {movie.content_properties[0].duration} min
                  </>
                )}
              </div>
            </div>
            <div className={styles.contentWrapper}>
              <Tabs defaultActiveKey="content" className={styles.tabs} variant="pills">
                <Tab tabClassName={styles.tab} eventKey="content" title={t('singleMoviePage.content').toUpperCase()}>
                  <div className={styles.content}>
                    <p>{movie.description}</p>
                  </div>
                </Tab>
              </Tabs>
            </div>
            {renderLogin()}
            {renderMenu()}
          </Col>
        </Row>
      );
    }
  };

  const renderLogin = () => {
    if (isAuthenticated) {
      return;
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    };
    return (
      <div className={styles.loginWrapper}>
        <h3>{t('singleMoviePage.loginDialog')}</h3>
        <form onSubmit={handleLogin} autoComplete="off">
          <Row className={styles.formWrapper}>
            <Col xs={12} sm={6} lg={4} style={{ marginBottom: '5px' }}>
              <input
                autoComplete="off"
                name="username"
                placeholder={t('singleMoviePage.customerNumber')}
                type="text"
                value={form.username}
                onChange={handleChange}
              />
            </Col>
            <Col xs={12} sm={6} lg={4} style={{ marginBottom: '5px' }}>
              <input
                maxLength={4}
                name="pin"
                placeholder={`${userLocation !== 'AT' ? '18+' : 'Sky'} ${t('singleMoviePage.pinCode')}`}
                autoComplete="off"
                type="text"
                value={pin
                  .split('')
                  .map((item) => '⚫')
                  .join('')}
                onChange={handlePinChange}
              />
            </Col>
            <Col xs={12} sm={6} lg={4}>
              <LoginButton loading={loading} handleLogin={handleLogin} disabled={pin?.length < 4 ? true : false} />
            </Col>
          </Row>
        </form>
      </div>
    );
  };

  return (
    <Container className={styles.mainWrapper}>
      <Suspense fallback={<CustomSpinner />}>{renderMovieDetails()}</Suspense>
      {movie && cfMediaID && renderStream()}
    </Container>
  );
};
export default MovieDetailsPage;
