import React, { FC, useEffect, useState } from 'react';
import styles from './EditPaymentMethod.module.scss';
import { PaymentMethodCard } from '../../components/PaymentMethodCard';
import { CustomSpinner } from '../../shared/Spinner';
import usePaymentTypesUsedQuery from '../../hooks/query/usePaymentTypesUsed';
import { PaymentUsed } from '../../hooks/models/PaymentUsedModel';
import useChangePrimaryPaymentMutation from '../../hooks/mutation/useChangePrimaryPaymentMutation';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useUpdatePaymentMutation from '../../hooks/mutation/useUpdatePaymentMutation';
import infoIcon from '../../assets/img/infoIcon.svg';
import infoIconRed from '../../assets/img/infoIconRed.svg';

interface EditPaymentMethodI {}

const EditPaymentMethod: FC<EditPaymentMethodI> = () => {
  const { paymentTypesUsed, isPaymentTypesUsedLoading } = usePaymentTypesUsedQuery();
  const { mutateChangePrimaryPayment } = useChangePrimaryPaymentMutation();
  const { mutateUpdatePayment } = useUpdatePaymentMutation();
  const [selectedPayment, setSelectedPayment] = useState<PaymentUsed>({
    id: -1,
    info: '',
    gateway: '',
    image: '',
  });
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (
      paymentTypesUsed &&
      Array.isArray(paymentTypesUsed.payment_methods) &&
      paymentTypesUsed.payment_methods.length > 0
    ) {
      setSelectedPayment(paymentTypesUsed.payment_methods[0]);
    }
  }, [paymentTypesUsed]);

  const handleSubmit = () => {
    mutateChangePrimaryPayment({ user_account_id: Number(selectedPayment.id) });
  };

  const handleDelete = (id: number) => {
    mutateUpdatePayment({ user_account_id: Number(selectedPayment.id), state: 'B' });
  };

  const handleCancel = () => {
    navigate(`/${language}/plus-account`);
  };

  if (isPaymentTypesUsedLoading) {
    return <CustomSpinner />;
  }

  return (
    <div className={styles.container}>
      <header>
        <h2>CHANGE PRIMARY PAYMENT METHOD</h2>
        <div>
          <button onClick={handleCancel} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleSubmit} className={styles.save}>
            Save
          </button>
        </div>
      </header>
      <hr />
      <div className={styles.cards}>
        {paymentTypesUsed &&
          Array.isArray(paymentTypesUsed.payment_methods) &&
          paymentTypesUsed.payment_methods
            .slice(0, 3)
            .map((payment: PaymentUsed) => (
              <PaymentMethodCard
                key={payment.id}
                selectedPayment={selectedPayment}
                setSelectedPayment={setSelectedPayment}
                payment={payment}
                handleDelete={handleDelete}
              />
            ))}
      </div>
      <div className={styles.info}>
        {paymentTypesUsed.message ? (
          <>
            <img src={infoIconRed} alt="" />
            <span>Die Zahlungsart können Sie nur bis zu drei Tage vor Ihrer nächsten Zahlung ändern.</span>
          </>
        ) : (
          <>
            <img src={infoIcon} alt="" />
            <span>
              Bitte beachten Sie, dass eine Änderung der Zahlungsart nur bis zu drei Tage vor der nächsten
              wiederkehrenden Zahlung möglich ist.
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default EditPaymentMethod;
