import { useMutation } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { ChangePrimaryPaymentBodyModel } from '../models/ChangePrimaryPaymentBodyModel';
import { queryClient } from '../../core/root/components/ReactQueryProvider/ReactQueryProvider';

const useUpdatePaymentMutation = () => {
  const { mutate: mutateUpdatePayment, isLoading: isUpdatePaymentMutationLoading } = useMutation({
    mutationFn: async (data: ChangePrimaryPaymentBodyModel) => {
      const response = await fetchRedirect(createUrl(`/payment/update-status`), {
        method: 'POST',
        body: JSON.stringify(data),
      });

      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['paymentTypesUsed']);
    },
  });

  return { mutateUpdatePayment, isUpdatePaymentMutationLoading };
};

export default useUpdatePaymentMutation;
