import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { PaymentUsedModel } from '../models/PaymentUsedModel';

type PaymentQueryModel = {
  paymentTypesUsed: PaymentUsedModel;
  isPaymentTypesUsedLoading: boolean;
};

const usePaymentTypesUsedQuery = (): PaymentQueryModel => {
  const { data, isLoading } = useQuery({
    queryKey: ['paymentTypesUsed'],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl('/payment/types'));
      if (res.response.ok) {
        return res.body;
      }
    },
  });

  const paymentTypesUsed = data?.data;
  const isPaymentTypesUsedLoading = isLoading;

  return { paymentTypesUsed, isPaymentTypesUsedLoading };
};

export default usePaymentTypesUsedQuery;
