import React, { FC } from 'react';
import styles from './Summary.module.scss';
import { useTranslation } from 'react-i18next';

interface SummaryI {
  price: number;
  bonusCode?: number;
  summary?: string;
}

const Summary: FC<SummaryI> = ({ price, bonusCode = 0, summary }) => {
  const { t } = useTranslation();

  const renderItem = (title: string, price?: number | string) => {
      const priceComma = price?.toString().replace('.', ',');

    return (
      <div className={styles.item}>
        <p>{title}</p>
        <span>
          {priceComma} {price?.toString().includes('€') ? '' : ' €'}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <hr />
      {renderItem(t('depositPage.numbers'), price)}
      {renderItem(t('depositPage.voucher'), bonusCode === 0 ? '00,00' : bonusCode)}
      <hr />
      <div className={`${styles.item} ${styles.summary}`}>
        <p>{t('depositPage.total')}</p>
        <span>
          {summary} {summary?.toString().includes('€') ? '' : ' €'}
        </span>
      </div>
    </div>
  );
};

export default Summary;
