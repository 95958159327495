import React from 'react';
import styles from './RadioInput.module.scss';

interface RadioInputProps {
  checked: boolean;
  id?: string;
  onChange?: () => void;
  className?: string;
  customStyles?: React.CSSProperties;
}

const RadioInput: React.FC<RadioInputProps> = ({ id, checked, onChange, className, customStyles }) => {
  return (
    <input
      type="radio"
      id={id}
      checked={checked}
      onChange={onChange}
      className={`${className} ${styles.radioButton}`}
      style={customStyles}
    />
  );
};

export default RadioInput;
