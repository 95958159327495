import React, { FC, useState } from 'react';
import Container from 'react-bootstrap/Container';
import styles from './PlusAccountPage.module.scss';
import { useTranslation } from 'react-i18next';
import { CustomSpinner } from '../../shared/Spinner';
import { EmailModal } from '../../components/UI/Modals/EmailModal';
import { useTicketsQuery } from './query';
import useGeneratePdfQuery from '../../hooks/query/useGeneratePdfQuery';
import editIcon from '../../assets/img/editIcon.svg';
import { useNavigate } from 'react-router-dom';

const PlusAccountPage: FC = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const { userTicket, isTicketsQueryLoading } = useTicketsQuery();
  const handleClose = () => setShow(false);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { mutate } = useGeneratePdfQuery();
  function firstCharToUpper(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  if (isTicketsQueryLoading) {
    return <CustomSpinner />;
  }

  return (
    <Container>
      <h2>{t('plusAccountPage.title')}</h2>
      <table className={styles.tableNoSideBorders}>
        <thead>
          <tr>
            <th>{t('plusAccountPage.yourTicket')}</th>
            <th>{t('plusAccountPage.nextCharge')}</th>
            <th>{t('plusAccountPage.status')}</th>
            <th style={{ display: 'flex', justifyContent: 'end' }}>{t('plusAccountPage.editPaymentMethod')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{userTicket?.ticket}</td>
            <td>{userTicket?.recurring_at ? userTicket.recurring_at : '--'}</td>
            <td>
              <div className={styles.status}>{userTicket?.status ? firstCharToUpper(userTicket.status) : '--'}</div>
            </td>
            <td style={{ display: 'flex', justifyContent: 'end' }}>
              <button
                onClick={() => navigate(`/${language}/edit-payment-method`)}
                className={styles.editPaymentMethodButton}
              >
                <img src={editIcon} alt={'editICon'} />
                Edit
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <EmailModal
        handleSuccess={() => setShow(false)}
        centered={true}
        show={show}
        ticket={userTicket}
        onHide={handleClose}
      />
    </Container>
  );
};

export default PlusAccountPage;
