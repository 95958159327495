import React, { Dispatch, FC, SetStateAction } from 'react';
import styles from './PaymentMethodCard.module.scss';
import { PaymentUsed, PaymentUsedModel } from '../../hooks/models/PaymentUsedModel';
import { generateImageUrl } from '../../utils/cdnService';
import { useTranslation } from 'react-i18next';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

interface PaymentMethodCardI {
  payment: PaymentUsed;
  selectedPayment: PaymentUsed;
  setSelectedPayment: Dispatch<SetStateAction<PaymentUsed>>;
  handleDelete: (id: number) => void;
}

const PaymentMethodCard: FC<PaymentMethodCardI> = ({ payment, selectedPayment, setSelectedPayment, handleDelete }) => {
  const { t } = useTranslation();
  const handleSelectPayment = () => {
    setSelectedPayment(payment);
  };
  const selected = payment.id === selectedPayment.id;

  const isDisabled = () => {
    if (payment.gateway === 'Segpay' || payment.gateway === 'Epoch') {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`${styles.container} ${selected ? styles.selected : ''} ${
        payment.state === 'B' ? styles.blocked : ''
      }`}
    >
      <div className={styles.mainContent}>
        <header>
          {payment.image && (
            <img
              className={`${styles.img} ${isDisabled() ? styles.paypalImg : ''}`}
              src={`${generateImageUrl('gcore', 0, 0, payment.image)}`}
              alt=""
            />
          )}
          <div style={{ background: payment.state === 'A' ? '#1a8754' : '#B51511' }} className={styles.status}>
            {payment.state === 'A' ? 'Aktiv' : 'Inaktiv'}
          </div>
          <button onClick={() => handleDelete(payment.id)} className={styles.trashButton}>
            <i className="fa fa-trash red-icon" style={{ color: 'red' }} aria-hidden="true"></i>
          </button>
        </header>
        {payment.info}
      </div>

      {isDisabled() ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="tooltip-disabled">
              <span>{t('changePaymentMethod.tooltip')}</span>
            </Tooltip>
          }
        >
          <button className={`${styles.btn} ${!selected ? styles.notSelected : ''} ${styles.disabled}`} disabled>
            {selected ? t('changePaymentMethod.active') : t('changePaymentMethod.change')}
          </button>
        </OverlayTrigger>
      ) : (
        <button className={`${styles.btn} ${!selected ? styles.notSelected : ''}`} onClick={handleSelectPayment}>
          {selected ? t('changePaymentMethod.active') : t('changePaymentMethod.change')}
        </button>
      )}
    </div>
  );
};

export default PaymentMethodCard;
